import { Controller } from '@hotwired/stimulus';
import {toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {

  static targets = [
    "input", "extraOptionsInput", "extraOptionsInputWrapper"
  ]

  static values = {
    additionalOptions: Array,
    initialSelection: String,
    moreThanThreeMonths: String
  }

  initialize() {

    $(this.extraOptionsInputTarget).on('change', () => {
      $(this.inputTarget).val(this.extraOptionsInputTarget.value).trigger('change');
    });

    if (this.additionalOptionsValue.includes(this.initialSelectionValue)) {
      this.toggleSelectInputUse(true);
      $(this.extraOptionsInputTarget).val(this.initialSelectionValue).trigger("change");
    } else {
      this.toggleSelectInputUse(false);
    }
  }

  onRadioSelect(event) {
    const radioValue = event.detail.element.value;
    this.updateSelection(radioValue);
  }

  updateSelection(radioValue) {
    if (this.previousValue === radioValue) {
      return;
    }
    this.previousValue = radioValue;
    const extraOptions = radioValue === this.moreThanThreeMonthsValue;

    this.toggleSelectInputUse(extraOptions);

    toggleHidden(this.extraOptionsInputWrapperTarget, !extraOptions);
    toggleDisabled(this.extraOptionsInputTarget, !extraOptions);

    $(this.extraOptionsInputTarget).val("").trigger("change");

    if (!extraOptions) {
      $(this.inputTarget).val(radioValue).trigger('change');
    }
  }

  toggleSelectInputUse(used) {
    toggleHidden(this.extraOptionsInputWrapperTarget, !used);
    toggleDisabled(this.extraOptionsInputTarget, !used);
  }

}
import {Controller} from "@hotwired/stimulus";
import {toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    'email',
    'emailWrapper',
    'unconfirmedEmail',
    'unconfirmedEmailWrapper'
  ]

  get isUnconfirmed() {
    return this._unconfirmed;
  }

  set isUnconfirmed(value) {
    this._unconfirmed = value;

    if (this.hasUnconfirmedEmailTarget) {
      toggleHidden(this.unconfirmedEmailWrapperTarget, !value);
    }
    toggleHidden(this.emailWrapperTarget, value);
    toggleDisabled(this.emailTarget, value);
  }

  initialize() {
    if (this.hasUnconfirmedEmailTarget) {
      this.isUnconfirmed = true;
      toggleDisabled(this.unconfirmedEmailTarget, true);
    } else {
      this.isUnconfirmed = false;
    }
  }

  revertToConfirmedTarget() {
    this.isUnconfirmed = false;
  }
}
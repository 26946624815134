import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tip'];

  show(event) {
    this.tipTarget.hidden = false;
  }

  hide(event) {
    this.tipTarget.hidden = true;
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["checkbox"]

  handleSelection() {
    if (this.hasCheckboxTarget) {
      $(this.checkboxTarget).removeClass('is-collapsed');
    }
  }
}
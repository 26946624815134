import { Controller } from "@hotwired/stimulus";
import {toggleHidden} from '../util';

export default class extends Controller {
  static targets = ["documentInput", "existingFileLabel"];

  initialize() {
    if (this.hasExistingFileLabelTarget && this.hasDocumentInputTarget) {
      $(this.documentInputTarget).on('change', () => {
        toggleHidden(this.existingFileLabelTarget, true);
      });
    }
  }
}

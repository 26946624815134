import { Controller } from "@hotwired/stimulus";
import { Events } from '../events';
import { toggleActive } from '../util';
import WindowScrollingService from '../services/window_scrolling_service';
import TrapFocusService from '../util/trapFocus';

const closeAllModals = () => {
  document.getElementsByClassName('modal').forEach((el) => el.classList.remove('is-active'));
};

export default class extends Controller {
  static targets = ['modalWindow'];

  static values = {
    openEvent: String,
    isSender: Boolean,
    openOnLoad: Boolean
  }

  initialize() {
    this.isOpen = false;
    this.focusableContent = [];
    if (this.hasModalWindowTarget) {
      const focusableElements = 'button, [href], [tabindex]';

      this.focusableContent = this.modalWindowTarget.querySelectorAll(focusableElements);
      this.focusableContent.forEach((element) => {
        element.addEventListener('focus', event => this.handleFocusChange(event, true));
        element.addEventListener('blur', event => this.handleFocusChange(event, false));
      });
    }

    if (!this.isSenderValue) {
      document.addEventListener(this.openEventValue, () => {
        this.open();
      });
    }
  }

  handleFocusChange(event, isFocused) {
    this.focusableContent.forEach(element => element.classList.remove('focus-visible'));
    if (isFocused && document.body.classList.contains('keyboard-focused')) {
      event.target.classList.add('focus-visible');
    }
  }

  connect() {
    const esc = (e) => {
      if (e.keyCode === 27) {
        this.close(null, true);
      }
    };
    document.addEventListener("keydown", esc);

    if (this.openOnLoadValue) {
      setTimeout(() => {
        this.open();
      }, 1);
    }
  }

  disconnect() {
    this.close();
    TrapFocusService.releaseAll();
  }

  open(event) {
    if (this.isOpen) {
      return;
    }

    this.openTrigger = event?.target;
    closeAllModals();
    event?.preventDefault();
    if (this.isSenderValue && this.openTrigger) {
      const openEventName = this.openTrigger.dataset.openEventName || this.openEventValue;
      if (openEventName) {
        const openEvent = new Event(openEventName);
        document.dispatchEvent(openEvent);
      }
    } else {
      this.isOpen = true;
      this.toggleModalActive(true);
      this.trapFocusInModal();
      this.dispatch('open', {detail: {}});
      WindowScrollingService.lock();
    }
  }

  close(event, keyboardControlled = false) {
    if (this.isOpen) {
      event?.preventDefault();
      this.isOpen = false;
      this.toggleModalActive(false);
      this.dispatch('close', {detail: {openEvent: this.openEventValue}});
      this.releaseFocusFromModal(keyboardControlled);
      WindowScrollingService.unlock();

      if (this.openTrigger) {
        this.openTrigger.focus();
      }
    }
  }

  toggleModalActive(isActive) {
    if (this.hasModalWindowTarget) {
      const eventName = isActive ? Events.modalActive : Events.modalInactive;
      const event = new Event(eventName);
      document.dispatchEvent(event);
      toggleActive(this.modalWindowTarget, isActive);
    }
  }

  trapFocusInModal() {
    this.focusTrap = TrapFocusService.trapFocus(this.focusableContent);
  }

  releaseFocusFromModal(keyboardControlled = false) {
    if (this.focusTrap) {
      this.focusTrap.release(keyboardControlled);
      this.focusTrap = null;
    }
  }
}

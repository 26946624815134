import { Controller } from "@hotwired/stimulus";
import {toggleClass} from '../util';

export default class extends Controller {
  static targets = [
    'input',
    'error',
    'subscript',
    'wrapper',
  ];

  initialize() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('keyup', () => {
        this.update();
      });
      this.inputTarget.addEventListener('change', () => {
        this.update();
      });
    }
    this.update();
  }

  update() {
    const isValid = this.isInputValid();
    if (this.hasWrapperTarget) {
      this.wrapperTarget.classList.toggle('is-valid', isValid);
    }

    this.errorTargets.forEach(target => toggleClass(target, 'is-hidden', true));

    const activeError = this.errorTargets.find(target => this.inputTarget.validity[target.dataset.attribute]);
    if (activeError != null) {
      toggleClass(activeError, 'is-hidden', false);
    }
    toggleClass(this.subscriptTarget, 'is-hidden', activeError != null);
  }

  isInputValid() {
    return this.inputTarget.value.trim() !== '' && this.inputTarget.checkValidity();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    this.inputTargets.forEach(element => {
      element.addEventListener('keyup', () => { this.update() });
    })
    this.update();
  }

  update(){
    this.inputTargets.forEach(target => {
      document.getElementsByClassName(target.dataset.dynamicTextClass).forEach(element => {
        const template = element.dataset.dynamicTextTemplate;
        if (template) {
          const decodedTemplate = decodeURI(template)
          element.innerHTML = decodedTemplate.replace(target.dataset.dynamicTextTemplate, target.value);
        }
      });
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['feedback'];

  static values = {
    url: String,
  };

  copy() {
    window.navigator.clipboard.writeText(this.urlValue).then(() => {
      if (this.hasFeedbackTarget) {
        this.feedbackTarget.classList.add('visible');
        if (typeof this.timeoutID === 'number') {
          clearTimeout(this.hideFeedbackTimer);
        }
        this.hideFeedbackTimer = setTimeout(() => { this.feedbackTarget.classList.remove('visible'); }, 1000);
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import { toggleDisabled, toggleSubmitting as toggleElemSubmitting} from "../util";

const openReferralInvitationSentModal = (name = '') => {
  const elem = document.getElementById('referral-invitation-sent-modal-message');
  if (elem) {
    elem.innerHTML = elem.innerHTML.replace('{{FIRST_NAME}}', name);
  }

  const openReferralInvitationSentModalEvent = new Event('referral-invitation-sent');
  document.dispatchEvent(openReferralInvitationSentModalEvent);
};

const toggleSubmitting = (elem, state) => {
  toggleDisabled(elem, state);
  toggleElemSubmitting(elem, state);
};
export default class extends Controller {
  static targets = ['name', 'email', 'submit', 'error'];

  initialize() {
    [this.nameTarget, this.emailTarget].forEach(target => {
      target.addEventListener('focus', () => { this.clearError(); });
    });
  }

  send() {
    const name = (this.hasNameTarget ? this.nameTarget.value : '');
    const email = this.emailTarget.value;
    const data = { name, email };
    this.clearError();
    toggleSubmitting(this.submitTarget, true);
    $.ajax({
      type: 'POST',
      url: '/rewards/invite.js',
      contentType: 'application/json',
      data: JSON.stringify(data),
      success: () => {
        openReferralInvitationSentModal(name);
        this.clearInputs();
      },
      error: ({ responseJSON }) => {
        this.errorTarget.innerHTML = responseJSON.message;
      },
      complete: () => { toggleSubmitting(this.submitTarget, false); }
    });
  }

  clearError() {
    this.errorTarget.innerHTML = '';
  }

  clearInputs() {
    this.nameTarget.value = '';
    this.emailTarget.value = '';
    this.emailTarget.dispatchEvent(new Event('change'));
  }
}

import { Controller } from "@hotwired/stimulus";
import { Events } from '../events';

export default class extends Controller {
  initialize() {
    document.addEventListener(Events.deadlineReached, () => {
      setTimeout(() => window.location.reload(), 2000);
    });
  }
}

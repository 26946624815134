import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input"];

  selectInput(event) {

    const {inputId} = event.target.dataset;
    let selectedElement = null;
    this.inputTargets.forEach(element => {
      if (element.dataset.wrapperId === inputId) {
        selectedElement = element;
        element.click();
      }
    });

    if (selectedElement) {
      this.dispatch('select', {detail: {element: selectedElement}});
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "industrySelect",
    "template"
  ];

  initialize() {
    $(this.industrySelectTarget).on('select2:select', () => {
      this.industrySelectTarget.dispatchEvent(new Event('change'));
    });
  }

  connect() {
  }

  setup() {
    if (this.set) {
      return;
    }
    const defaultIndustry = JSON.parse(this.templateTarget.dataset.args).industry;
    $(this.industrySelectTarget).val(defaultIndustry);
    this.industrySelectTarget.dispatchEvent(new Event('change'));
    this.set = true;
  }

  updateTemplateArgs() {
    this.templateTarget.dataset.args = JSON.stringify({ industry: this.industrySelectTarget.value });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['avatar'];

  initialize() {
    window.addEventListener('scroll', () => { this.handleScroll() });
  }

  handleScroll() {
    if (this.hasAvatarTarget) {
      this.avatarTarget.classList.toggle('is-shifted', window.scrollY > 30);
    }
  }
}

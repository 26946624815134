import Cropper from 'cropperjs';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['cancel', 'dropzone', 'save'];

  setup(imageElement) {
    this.toggleDropzoneDisplay(false);
    this.cropper = new Cropper(imageElement, {
      aspectRatio: 1.0,
      crop: this.onCrop(),
      zoomable: false,
    })
  }

  onCrop() {
    return (event) => {
      [
        ['cropX', 'x'],
        ['cropY', 'y'],
        ['cropW', 'width'],
        ['cropH', 'height'],
      ].forEach(item => {
          const value = parseInt(event.detail[item[1]], 10);
          document.getElementById(item[0]).value = value;
      });
      this.saveTarget.removeAttribute('disabled');
    }
  }

  toggleDropzoneDisplay(isDisplay) {
    this.dropzoneTarget.style.display = isDisplay ? 'flex' : 'none';
  }

  close() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    this.toggleDropzoneDisplay(true);
    this.dropzoneController.reset();
  }

  save() {
    this.saveTarget.setAttribute('disabled', true);
    showBusy(this.saveTarget); // global function from busy-on-submit script
    this.cancelTargets.forEach(target => target.setAttribute('disabled', true));
    this.dropzoneController.save();
  }

  get dropzoneController() {
    const el = document.getElementById('cropperDropzone')
    return this.application.getControllerForElementAndIdentifier(el, 'cropper-dropzone');
  }
}

import { Controller } from "@hotwired/stimulus";
import {
  dispatchChangeEvent,
  toggleDisabled, toggleHidden,
  toggleRequired,
} from '../util';

export default class extends Controller {
  static targets = [
    'checkbox',
    'select',
    'textInput',
    'textWrapper',
  ];

  initialize() {
    this.checkboxTarget.addEventListener('change', () => { this.updateActiveInputMethod(); });
    document.addEventListener('universitySelectChange', () => { this.copySelectToInput(); });
    this.updateInputs();
  }

  updateActiveInputMethod() {
    if (this.isUsingTextInput) {
      this.selectTarget.value = null;
      this.textInputTarget.value = '';
    } else {
      this.selectTarget.selectedIndex = 0;
    }
    this.updateInputs();
  }

  copySelectToInput() {
    const index = this.selectTarget.selectedIndex;
    if (index != null && index >= 0) {
      const option = this.selectTarget.options[index];
      if (option) {
        this.textInputTarget.value = option.value;
      }
    }
  }

  updateInputs() {
    this.toggleTextInput(this.isUsingTextInput);
    this.toggleSelectInput(!this.isUsingTextInput);
    dispatchChangeEvent(this.selectTarget);
  }

  toggleSelectInput(isEnabled) {
    toggleRequired(this.selectTarget, isEnabled);
    toggleDisabled(this.selectTarget, !isEnabled);
  }

  toggleTextInput(isEnabled) {
    toggleRequired(this.textInputTarget, isEnabled);
    toggleHidden(this.textWrapperTarget, !isEnabled);
  }

  get isUsingTextInput() {
    return this.checkboxTarget.checked;
  }
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@hotwired/turbo-rails';
import Turbo from '@hotwired/turbo';
import Sortable from 'sortablejs';
import 'controllers';
import 'styles/application.css';


document.addEventListener('turbo:load', () => {
  const el = document.getElementById('sortable-list');
  if (el) {
    new Sortable.create(el, {
      animation: 150,
      delay: 50,
      delayOnTouchOnly: true,
    });
  }
});

import { Controller } from "@hotwired/stimulus";
import { toggleDisabled } from '../util';

export default class extends Controller {
  static targets = ['input', 'button', 'confirmation', 'wrapper'];

  initialize() {
    this.inputTargets.forEach(element => {
      element.addEventListener('keyup', () => { this.update(); });
      element.addEventListener('change', () => { this.update(); });
    });
  }

  update() {
    if (this.inputTargets.length > 0) {
      const values = this.inputTargets.map(element => element.value);
      const allAreEqual = values.every(v => v === values[0]);
      this.updateButton(!allAreEqual);
      this.updateConfirmation(!allAreEqual);
    }
    this.emitPostUpdateEvent();
  }

  updateButton(isNotConfirmed) {
    this.buttonTargets.forEach(element => toggleDisabled(element, isNotConfirmed));
  }

  updateConfirmation(state) {
    if (this.hasConfirmationTarget) {
      this.confirmationTarget.setCustomValidity(state ? 'Passwords do not match' : '');
    }
  }

  emitPostUpdateEvent() {
    if (!this.hasButtonTarget) {
      $(this.confirmationTarget).trigger('change');
    }
  }
}

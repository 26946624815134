import { Controller } from "@hotwired/stimulus";
import { toggleDisabled, toggleSubmitting } from '../util';

export default class extends Controller {
  static targets = ['input', 'feedback', 'form', 'submitButton'];

  connect() {
    this.toggleSubmittingState(false);
    this.update();
  }

  update(){
    const isFormInvalid = !this.checkFormValidity();
    this.disableSubmitButton(isFormInvalid);
    this.updateFeedback();
  }

  selectInput(event) {
    const inputId = event.target.dataset.inputId;
    this.inputTargets.forEach(element => {
      if (element.dataset.wrapperId === inputId) {
        element.click();
      }
    });
  }

  submit() {
    this.toggleSubmittingState(true);
    this.disableSubmitButton(true);
    this.formTarget.submit();
  }

  checkFormValidity() {
    return this.inputTargets.find(elem => elem.checked) !== undefined;
  }

  updateFeedback() {
    const selectedInput = this.inputTargets.find(elem => elem.checked);
    if (selectedInput) {
      const targetId = selectedInput.dataset.wrapperId || '';
      this.feedbackTargets.forEach(element => {
        element.classList.toggle('is-active', element.dataset.feedbackId == targetId)
      });
    }
  }

  toggleSubmittingState(isSubmitting) {
    this.submitButtonTargets.forEach(element => toggleSubmitting(element,  isSubmitting));
  }

  disableSubmitButton(isDisabled) {
    this.submitButtonTargets.forEach(element => toggleDisabled(element, isDisabled));
  }
}

import {Controller} from "@hotwired/stimulus";
import { Events } from '../events';
import { toggleAriaExpanded, toggleHidden } from '../util';
import TrapFocusService, {isTabPressed, trapFocus} from '../util/trapFocus';

const notTouchEvent = (event) => event.pointerType !== 'touch';
export default class extends Controller {
    static targets = ['menu', 'trigger', "menuWrapper"];

    initialize() {
        this.initializeFocusTrapping();
        this.controlledByPointer = false;
    }

    connect() {
        this.closeDropdown()
        this.addComponentEventListeners();
    }

    disconnect() {
        this.closeDropdown();
        this.removeComponentEventListeners();
    }

    addComponentEventListeners() {
        this.enterTriggerHandler = (e) => {
            if (e.pointerType === 'touch') {
                this.toggleDropdown();
            } else if (!this.dropdownOpen) {
                this.controlledByPointer = true;
                this.debounceOpenDropdown();
            }
        };

        this.leaveTriggerHandler = (e) => {
            if (notTouchEvent(e) && this.controlledByPointer) {
                this.debounceCloseDropdown();
            }
        };

        this.menuEnterHandler = (e) => {
            if (notTouchEvent(e)) {
                this.debounceOpenDropdown();
            }
        };

        this.menuLeaveHandler = (e) => {
            if (notTouchEvent(e) && this.controlledByPointer) {
                this.debounceCloseDropdown();
            }
        };

        this.documentClickHandler = (e) => {
            if (notTouchEvent(e) && !this.controlledByPointer && this.dropdownOpen) {
                const {target} = e;

                if(!this.controlledByPointer && this.focusableContentArray.indexOf(target) !== -1) {
                    return;
                }

                if (!(target === this.menuTarget || target === this.menuWrapperTarget
                  || target.offsetParent === this.menuWrapperTaret)) {
                    this.closeDropdown();
                } else {
                    this.controlledByPointer = true;
                }
            }
        };

        this.handleTriggerKeyboardEvent = (event) => {
            if (!this.dropdownOpen && (event.key === 'Enter' || event.key === 'ArrowDown')) {
                event.preventDefault();
                this.controlledByPointer = false;
                this.debounceOpenDropdown();
            }
        };

        this.handleMenuKeyboardEvent = (event) => {
            if (!this.dropdownOpen) {
                return;
            }

            if (isTabPressed(event)) {
                this.controlledByPointer = false;
            } else if (event.code === 'Escape') {
                event.preventDefault();
                this.closeDropdown();
                this.triggerTarget.focus();
            }
        };

        setTimeout(() => {
            this.triggerTarget.addEventListener(Events.pointerEnter, this.enterTriggerHandler);
            this.triggerTarget.addEventListener(Events.pointerLeave, this.leaveTriggerHandler);
            this.triggerTarget.addEventListener(Events.keyDown, this.handleTriggerKeyboardEvent);
            this.menuTarget.addEventListener(Events.pointerEnter, this.menuEnterHandler);
            this.menuTarget.addEventListener(Events.pointerLeave, this.menuLeaveHandler);
            document.addEventListener(Events.click, this.documentClickHandler);
            document.addEventListener(Events.keyDown, this.handleMenuKeyboardEvent);
        }, 100);
    }

    removeComponentEventListeners() {
        if (this.enterTriggerHandler) {
            this.triggerTarget.removeEventListener(Events.pointerEnter, this.enterTriggerHandler);
        }
        if (this.menuEnterHandler) {
            this.menuTarget.removeEventListener(Events.pointerEnter, this.menuEnterHandler);
        }
        if (this.leaveTriggerHandler) {
            this.triggerTarget.removeEventListener(Events.pointerLeave, this.leaveTriggerHandler);
        }
        if (this.menuLeaveHandler) {
            this.menuTarget.removeEventListener(Events.pointerLeave, this.menuLeaveHandler);
        }
        if (this.documentClickHandler) {
            document.removeEventListener(Events.click, this.documentClickHandler);
        }

        if (this.handleTriggerKeyboardEvent) {
            this.triggerTarget.removeEventListener(Events.keyDown, this.handleTriggerKeyboardEvent);
        }
        if (this.handleMenuKeyboardEvent) {
            document.removeEventListener(Events.keyDown, this.handleMenuKeyboardEvent);
        }
    }

    initializeFocusTrapping() {
        const focusableElements = '.link';
        this.focusableContent = this.menuTarget.querySelectorAll(focusableElements);
    }

    toggleDropdown() {
        if (this.dropdownOpen) {
            this.debounceCloseDropdown();
        } else {
            this.debounceOpenDropdown();
        }
    }

    debounceOpenDropdown() {
        if (this.closeWindowTimer) {
            clearTimeout(this.closeWindowTimer);
            this.closeWindowTimer = undefined;
        }
        this.openDropdown();
    }

    debounceCloseDropdown() {
        this.closeWindowTimer = setTimeout(() => {
            this.closeDropdown();
        }, 80);
    }

    openDropdown() {
        if (this.dropdownOpen) {
            return;
        }
        this.dropdownOpen = true;
        this.trapFocusInMenu();
    }

    closeDropdown() {
        if (!this.dropdownOpen) {
            return;
        }
        this.dropdownOpen = false;
        this.controlledByPointer = false;
        this.releaseFocusFromMenu();
    }

    trapFocusInMenu() {
        this.focusTrap = TrapFocusService.trapFocus(this.focusableContent, !this.controlledByPointer);
        this.focusTrap.onPause(() => {
            this.removeComponentEventListeners();
        });

        this.focusTrap.onResume(() => {
            if (this.dropdownOpen && this.controlledByPointer) {
                this.debounceCloseDropdown();
            }
            this.addComponentEventListeners();

            setTimeout(() => {
                if (this.focusableContentArray.indexOf(document.activeElement) < 0) {
                    this.closeDropdown();
                }
            }, 10);
        });
    }

    releaseFocusFromMenu() {
        if (this.focusTrap) {
            this.focusTrap.release();
            this.focusTrap = null;
        }
    }

    get dropdownOpen() {
        return this._dropdownOpen;
    }

    get focusableContentArray() {
        return Array.prototype.slice.call(this.focusableContent)
    }

    set dropdownOpen(value) {
        if (this.dropdownOpen === value) {
            return;
        }
        toggleHidden(this.menuTarget, !value);
        toggleAriaExpanded(this.triggerTarget, value);
        this._dropdownOpen = value;
    }
}

import { Controller } from "@hotwired/stimulus";
import Toastify from 'toastify-js';
import humps from 'humps';
import { toggleDisabled, toggleHidden } from '../util';

const parseInput = rawValue => parseInt(rawValue ?? "0", 10);

const resetSelectInput = elem => $(elem).val(null).trigger('change');

const setDayTarget = (target, date) => {
  $(target).val(date.getDay()).trigger('change');
};

const setTimeTarget = (target, date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const minutesFromMidnight = hours * 60 + minutes;
  $(target).val(minutesFromMidnight).trigger('change');
};

const showToast = (text) => {
  Toastify({
    text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: 'right',
    offset: {
      x: '0.5rem',
      y: '6rem'
    },
    stopOnFocus: true
  }).showToast();
};

const WEEKDAYS =  [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export default class extends Controller {
  static targets = [
    'form',
    'eventId',
    'day',
    'startTime',
    'endTime',
    'error',
    'save',
    'delete',
  ]

  initialize() {
    document.addEventListener('select2-change', () => { this.update(); });
    this.update();
    this.eventBeingUpdated = null;
  }

  update() {
    this.updateSaveButton();
    this.updateDeleteButton();
    this.updateErrorMessage();
  }

  setup() {
    const id = this.eventId;
    if (id) {
      this.eventBeingUpdated = scheduler.getEvent(id);
      const {startDate, endDate} = humps.camelizeKeys(this.eventBeingUpdated);
      setDayTarget(this.dayTarget, startDate);
      setTimeTarget(this.startTimeTarget, startDate);
      setTimeTarget(this.endTimeTarget, endDate);
      scheduler.deleteEvent(id);
      this.clearId();
      this.update();
    } else {
      [
        this.dayTarget,
        this.startTimeTarget,
        this.endTimeTarget,
      ].forEach(elem => resetSelectInput(elem));
    }
  }

  updateSaveButton() {
    const isEnabled = this.formIsValid && this.periodIsValid;
    toggleDisabled(this.saveTarget, !isEnabled);
  }

  updateDeleteButton() {
    toggleHidden(this.deleteTarget, !this.eventBeingUpdated);
  }

  updateErrorMessage() {
    if (!this.formIsValid) {
      toggleHidden(this.errorTarget, true);
      return;
    }
    if (!this.timeOrderIsValid) {
      this.showErrorMessage('Must be later than the start time.');
      return;
    }
    if (!this.periodIsValid) {
      this.showErrorMessage('This overlaps an existing availability period. You can either change the period here or edit/delete the existing period.');
      return;
    }
    toggleHidden(this.errorTarget, true);
  }

  showErrorMessage(message = '') {
    this.errorTarget.innerHTML = message;
    toggleHidden(this.errorTarget, false);
  }

  save() {
    const {startDate, endDate} = humps.camelizeKeys(this.getSchedulerEvent());
    scheduler.addEvent(startDate, endDate);
    scheduler.updateView();
    this.dispatch('done', { detail: {}});
    if (this.eventBeingUpdated) {
      showToast('Availability period updated');
      this.eventBeingUpdated = null;
    } else {
      showToast(`Availability period added to ${WEEKDAYS[this.dayTarget.value]}`);
    }
  }

  delete() {
    this.eventBeingUpdated = null;
    scheduler.callEvent('onEventDeleted');
    this.dispatch('done', { detail: {}});
    showToast('Availability period deleted');
  }

  restore({detail: {openEvent}}) {
    if (openEvent === 'scheduler-add-event-modal' && this.eventBeingUpdated) {
      this.eventBeingUpdated.id = undefined;
      scheduler.addEvent(this.eventBeingUpdated);
      scheduler.updateView();
      this.eventBeingUpdated = null;
    }
  }

  getSchedulerEvent() {
    if (!this.formIsValid) {
      return undefined;
    }
    // MAGIC The minDate of the scheduler is a Sunday, so the values on the
    // day select input offset to the correct day for the event.
    const { minDate } = humps.camelizeKeys(scheduler.getState());
    let date = scheduler.date.add(minDate, parseInput(this.dayTarget.value, 10), 'day');
    date = scheduler.date.day_start(date);
    const startDate = scheduler.date.add(date, parseInput(this.startTimeTarget.value), 'minute');
    const endDate = scheduler.date.add(date, parseInput(this.endTimeTarget.value), 'minute');
    return {start_date: startDate, end_date: endDate};
  }

  clearId() {
    this.eventIdTarget.value = '';
  }

  get eventId() {
    if (this.eventIdTarget.value === '') {
      return undefined;
    }
    return parseInt(this.eventIdTarget.value, 10);
  }

  get formIsValid() {
    return this.formTarget.checkValidity();
  }

  get timesAreMissing() {
    return this.startTimeTarget.value === '' || this.endTimeTarget.value === '';
  }

  get timeOrderIsValid() {
    if (this.timesAreMissing) {
      return true;
    }
    const startMinutes = parseInput(this.startTimeTarget.value);
    const endMinutes = parseInput(this.endTimeTarget.value);
    return endMinutes > startMinutes;
  }

  get periodIsValid() {
    const event = this.getSchedulerEvent();
    if (event === undefined) {
      return true;
    }
    return scheduler.checkCollision(event);
  }
}
import { Controller } from "@hotwired/stimulus";
import { toggleDisabled } from '../util';

const validateElement = (element) => {
  if (element.getAttribute('type') === 'hidden'
    && $(element).data('skip-validation-when-disabled') && element.getAttribute('disabled')) {
    return true;
  }

  if (element.getAttribute('type') === 'hidden' && element.getAttribute('required') === 'true') {
    return element.getAttribute('value') !== '';
  }

  return element.checkValidity();
};

export default class extends Controller {
  static targets = ['input', 'form', 'submit', 'checkbox'];

  initialize() {
    this.inputTargets.forEach(element => {
      if (element.tagName === 'SELECT') {
        return;
      }
      $(element).on('keyup', () => { this.update(); });
      $(element).on('change', () => { this.update(); });
    });
    this.checkboxTargets.forEach((element) => {
      $(element).on('change', () => { this.update();   });
    });

    document.addEventListener('select2-change', () => { this.update(); });
    this.update();
  }

  update(){
    const isInvalid = this.hasInvalidInputs();
    this.submitTargets.forEach(element => toggleDisabled(element, isInvalid));
  }

  hasInvalidInputs() {
    return this.inputTargets.find(element => !validateElement(element)) !== undefined;
  }

  submit() {
    this.submitTargets.forEach(target => {
      toggleDisabled(target, true);
      target.classList.add('is-submitting');
    });
    this.formTarget?.submit();
  }
}

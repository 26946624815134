import { Controller } from "@hotwired/stimulus";
import {toggleClass} from '../util';

export default class extends Controller {

  initialize() {
    document.addEventListener('countdownOpened', () => {
      toggleClass(this.element, 'countdown-visible', true);
    });

    document.addEventListener('countdownClosed', () => {
      toggleClass(this.element, 'countdown-visible', false);
    });
  }
}
import { Controller } from "@hotwired/stimulus";
import {debounceTime, toggleDisabled} from '../util';

const validateElement = (element) => {
  if (element.getAttribute('type') === 'hidden'
    && $(element).data('skip-validation-when-disabled') && element.getAttribute('disabled')) {
    return true;
  }
  return element.checkValidity();
};

export default class extends Controller {
  static targets = ['input', 'submit', 'checkbox', 'form'];

  static values = {
    skipInitSubmit: Boolean,
  };

  initialize() {
    this.inputTargets.forEach(element => {
      if (element.tagName === 'SELECT') {
        return;
      }
      this.updateOnEvent(element, 'keyup');
      this.updateOnEvent(element, 'change');
    });
    this.checkboxTargets.forEach((element) => {
      this.updateOnEvent(element, 'change');
    });
    this.initSubmitAction();
    this.initBusyListeners();
    this.updateOnEvent(document, 'select2-change');
    this.update();
  }

  initBusyListeners() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('busy:begin', () => this.toggleSubmitting(true));
      this.formTarget.addEventListener('busy:end', () => this.toggleSubmitting(false));
    }
  }

  updateOnEvent(element, event) {
    const debounceUpdate = debounceTime(() => {
      this.update();
    }, 0);

    $(element).on(event, () => {
      debounceUpdate();
    });
  }

  update() {
    const isInvalid = this.hasBlankInputs() || this.hasInvalidInputs();
    this.submitTargets.forEach(element =>
      toggleDisabled(element, isInvalid)
    );
  }

  hasBlankInputs() {
    return this.inputTargets.find(
      element => element.hasAttribute("required")
        && element.value.trim() === ''
    ) !== undefined;
  }

  hasInvalidInputs() {
    return this.inputTargets.find(element => !validateElement(element)) !== undefined;
  }

  toggleSubmitting(state) {
    this.submitTargets.forEach(target => {
      toggleDisabled(target, state);
      target.classList.toggle('is-submitting', state);
    });
  }

  initSubmitAction() {
    if (this.hasFormTarget && !this.skipInitSubmitValue) {
      this.formTarget.addEventListener('submit', () => {
        this.toggleSubmitting(true);
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { toggleHidden } from "../util";

export default class extends Controller {
  static targets = ['button', 'loading', 'error'];

  static values = {
    paymentFor: String,
  }

  connect() {
    if (this.hasButtonTarget) {
      $.ajax({
        type: 'GET',
        url: `/payments/${this.paymentForValue}/stripe_checkout_session`,
        success: ({ url }) => {
          $(this.buttonTarget).attr("href", url);
          toggleHidden(this.buttonTarget, false);
        },
        error: () => {
          toggleHidden(this.errorTarget, false);
        },
        complete: () => {
          toggleHidden(this.loadingTarget, true);
        },
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form'];

  static values = {
    isReassignment: Boolean,
    modalOpenEvent: String,
  };

  openConfirm() {
    const event = new Event(this.modalOpenEventValue);
    document.dispatchEvent(event);
  }

  next() {
    if (this.isReassignmentValue) {
      this.openConfirm();
    } else {
      this.submit();
    }
  }

  submit() {
    if (this.formTarget) {
      const busyEvent = new Event('busy:begin');
      this.formTarget.dispatchEvent(busyEvent);

      // This delay is needed for Safari to complete making the styling and content changed to
      // the button that are triggered by the busy:begin event.
      setTimeout(() => {
        this.formTarget.submit();
      }, 200);
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import {toggleDisabled, toggleRequired} from '../util';

export default class extends Controller {
  static targets = [
    'checkbox',
    'input',
  ];

  initialize() {
    this.checkboxTarget.addEventListener('change', () => { this.update(); });
    this.update();
  }

  update() {
    if (this.checkboxTarget.checked) {
      $(this.inputTarget).val(null);
      toggleDisabled(this.inputTarget, true);
      toggleRequired(this.inputTarget, false);
    } else {
      toggleDisabled(this.inputTarget, false);
      toggleRequired(this.inputTarget, true);
    }
    $(this.inputTarget).trigger('change');
  }
}

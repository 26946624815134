import { Controller } from '@hotwired/stimulus';
import {toggleClass, toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    'tab',
    'content',
  ]

  initialize() {
    this.tabs = {};
    this.tabTargets.forEach((target, idx) => {
      const id = target.dataset.tabId ?? idx;

      this.tabs[id] = target;
    });

    this.tabTargets.forEach((target, idx) => {
      target.addEventListener('click', () => {
        const id = target.dataset.tabId ?? idx;
        this.selectTab(id);
      });
    });
  }
  
  connect() {
    const id = this.tabTargets[0].dataset.tabId ?? 0;
    this.selectTab(id);
  }

  updateFromDropdownChoice(controllerEvent) {
    this.selectTab(controllerEvent.detail.value);
  }

  selectTab(tabId) {
    if (tabId == null || !Object.keys(this.tabs).includes(`${tabId}`) || this.tabId === tabId) {
      return;
    }

    if (this.tabs[tabId].dataset.isUrl === "true") {
      return;
    }

    this.tabId = tabId;
    this.updateTabTargets();
    this.updateContentTargets();
    this.dispatchTabOpenEvent();
  }

  updateTabTargets() {
    this.tabTargets.forEach(tab => {
      toggleClass(tab, 'active', tab === this.tabs[this.tabId]);
    });
  }

  updateContentTargets() {
    const tabId = this.tabId;
    this.contentTargets.forEach((content, idx) => {
      const id = content.dataset.tabId ?? idx;
      toggleHidden(content, tabId !== id);
    });
  }

  dispatchTabOpenEvent() {
    const eventName = `tab${_.upperFirst(this.tabId)}Open`;
    this.dispatch(eventName, { detail: {}});
    document.dispatchEvent(new Event(eventName));
    this.dispatch('change', { detail: {id: this.tabId}});
  }
}

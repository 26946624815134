import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['gallery'];

  initialize() {
    this.hidden = true;
  }

  update() {
    this.galleryTarget.classList.toggle('is-hidden', this.hidden);
  }

  toggle() {
    this.hidden = !this.hidden;
    this.update();
  }

  hide() {
    this.hidden = true;
    this.update();
  }
}

import {Controller} from "@hotwired/stimulus";
import {toggleClass, toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    "form",
    "messageInput",
    "errorMessage",
    "success",
  ]

  initialize() {
    toggleHidden(this.successTarget, true);
    toggleHidden(this.formTarget, false);
    this.element.addEventListener('submit', (event) => {
      this.onFormSubmission(event);
    });
  }

  onFormSubmission(event) {
    event.preventDefault();
    const url = this.formTarget.getAttribute('action');
    console.log(url)
    const message = this.messageInputTarget.value;
    this.toggleButtonLoader(true);
    $.ajax({
      type: 'POST',
      url: `${url}.json`,
      contentType: 'application/json',
      data: JSON.stringify({message}),
      success: this.onFormSuccess(),
      error: this.onFormError()
    });
  }

  onFormSuccess() {
    return () => {
      this.messageInputTarget.value = "";
      this.toggleButtonLoader(false);
      this.showSuccessState();
    };
  }

  onFormError() {
    return (xhr) => {
      const errorBody = JSON.parse(xhr.responseText ?? {});
      this.errorMessageTarget.textContent = errorBody?.message
        ?? "An error with your message has occurred, please try again.";
      this.toggleButtonLoader(false);
    };
  }

  toggleButtonLoader(isLoading) {
    this.formTarget.querySelectorAll('[type="submit"]').forEach(submit => {
      toggleClass(submit, 'is-submitting', isLoading);
      toggleDisabled(submit, isLoading);
    });
  }

  showSuccessState() {
    toggleHidden(this.successTarget, false);
    toggleHidden(this.formTarget, true);
  }

  showFormState() {
    toggleHidden(this.successTarget, true);
    toggleHidden(this.formTarget, false);
  }
}

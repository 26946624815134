import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['arrow', 'content', 'trigger'];

  initialize() {
    this.open = this.element.dataset?.collapsibleInitialState === 'open' ?? false;
    this.direction = this.element.dataset?.collapsibleDirection ?? 'normal';

    this.delay = 'fast';

    if (!this.open) {
      this.contentTarget.style.display = 'none';
    }
    this.updateChevron();
  }

  toggle(event) {
    this.open = !this.open;
    this.startSlideAnimation();
    this.updateChevron();
    this.triggerTarget.classList.toggle('open', this.open);
  }

  startSlideAnimation() {
    if (this.open) {
      $(this.contentTarget).slideDown(this.delay);
    } else {
      $(this.contentTarget).slideUp(this.delay);
    }
  }

  updateChevron() {
    if ((this.open && this.direction === 'normal') || (!this.open && this.direction === 'reverse')) {
      $(this.arrowTarget)
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-up');
    } else {
      $(this.arrowTarget)
        .removeClass('fa-chevron-up')
        .addClass('fa-chevron-down');
    }
  }
}

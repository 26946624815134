import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'accountNo',
    'accountYes',
    'back',
    'errors',
    'form',
    'step',
    'submitButton',
  ];
  static values = {
    initialStep: Number,
    showErrorStep: Number,
  };

  initialize() {
    if (this.hasErrorsTarget && this.errorsTarget.value !== '') {
      this.currentStep = this.showErrorStepValue;
    } else {
      this.currentStep = this.initialStepValue;
    }
    this.updateVisible();
  }

  updateVisible() {
    this.stepTargets.forEach((element, index) => {
      element.classList.toggle('is-active', index == this.currentStep);
    });
  }

  handleExistingAccountAnswer(event) {
    event.preventDefault();
    if (this.accountYesTarget.checked) {
      window.location.href = '/users/sign_in';
    } else {
      this.currentStep += 1;
      this.updateVisible();
    }
  }

  submitForm(_event) {
    if (this.submitButtonTargets.length > 0) {
      this.submitButtonTargets.forEach(element => {
        element.classList.add('is-submitting');
        element.setAttribute('disabled', true);
      });
    }
    if (this.hasFormTarget) {
      this.formTarget.submit();
    }
  }

  goToNextStep() {
    if (this.currentStep + 1 < this.stepTargets.length) {
      this.currentStep += 1;
      this.updateVisible();
    }
  }

  goToPreviousStep() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
      this.updateVisible();
    }
  }

  isSubmitButtonEnabled() {
    const disabledButtons = this.submitButtonTargets.filter(target => target.getAttribute('disabled') === 'true');
    return disabledButtons.length === 0;
  }

  handleKeydown(event) {
    if (event.type === 'keydown' && event.code === 'Enter' && this.isSubmitButtonEnabled()) {
      this.submitForm();
    }
  }
}

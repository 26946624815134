import { Controller } from "@hotwired/stimulus";
import { toggleDisabled, toggleHidden, toggleSubmitting } from '../util';

export default class extends Controller {
  static targets = ['alert', 'button', 'form', 'hint', 'input', 'submit'];
  static values = {
    minRequired: Number,
    maxAllowed: Number,
  }

  connect() {
    this.disableTagButtons(false);
    this.updateSubmitButtons();
    this.updateTagButtons();
    this.toggleSubmittingState(false);
  }

  toggle(event) {
    event.target.classList.toggle('selected');
    const isSelected = event.target.classList.contains('selected');
    event.target.setAttribute('aria-pressed', isSelected);
    this.adjustTagSet(event.target.dataset.value, isSelected);
  }

  adjustTagSet(value, isSelected) {
    const tags = this.tags;
    const index = tags.indexOf(value);
    if (index == -1 && isSelected) {
      tags.push(value);
    }
    if (index != -1 && !isSelected) {
      tags.splice(index, 1);
    }
    this.inputTarget.value = tags.join(',');
    this.updateSubmitButtons();
    this.updateTagButtons();
  }

  updateSubmitButtons() {
    this.submitTargets.forEach(target => {
      toggleDisabled(target, this.tags.length < this.minRequiredValue);
    })
  }

  updateTagButtons() {
    if (this.maxAllowedValue > this.minRequiredValue && this.tags.length >= this.maxAllowedValue) {
      this.showHint();
      if (this.hasAlertTarget) {
        this.alertTarget.setAttribute('role', 'alert');
      }
      this.buttonTargets.forEach(target => {
        if (!target.classList.contains('selected')) {
          toggleDisabled(target, true);
        }
      })
    } else {
      this.showHint(false);
      if (this.hasAlertTarget) {
        this.alertTarget.setAttribute('role', '');
      }
      this.disableTagButtons(false);
    }
  }

  submit() {
    toggleDisabled(this.submitTarget, true);
    this.disableTagButtons(true);
    this.toggleSubmittingState(true);
    this.formTarget?.submit();
  }

  disableTagButtons(isDisabled) {
    this.buttonTargets.forEach((target)=>{
      toggleDisabled(target, isDisabled);
    })
  }

  toggleSubmittingState(isSubmitting) {
    this.submitTargets.forEach(element => toggleSubmitting(element, isSubmitting));
  }

  showHint(state = true) {
    if (this.hasHintTarget) {
      toggleHidden(this.hintTarget, !state);
    }
  }

  get tags() {
    return this.inputTarget.value === '' ? [] : this.inputTarget.value.split(',');
  }
}

import { Controller } from "@hotwired/stimulus";

 const getJSONDataFromElement = (element, key) => element?.dataset[key] ? JSON.parse(element.dataset[key]) : {};
export default class extends Controller {
  static targets = [
    "widget",
    "extraPrefill",
    "extraUtmContent",
  ];

  load() {
    const widget = this.widgetTarget;
    const options = {
      parentElement: widget,
      url: widget.dataset.url,
      prefill: this.buildJsonData("prefill"),
      utm: {
        utmContent: encodeURIComponent(JSON.stringify(this.buildJsonData("utmContent")))
      }
    };

    Calendly.initInlineWidget(options);
  }

  unload() {
    this.widgetTarget.innerHTML = "";
  }

  buildJsonData(key) {
    let result = {...getJSONDataFromElement(this.widgetTarget, key)};
    this.extraPrefillTargets.forEach(element => {
      result = {
        ...result,
        ...getJSONDataFromElement(element, key)
      };
    });
    return result;
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tabContent'];

  makeActive(event) {
    document
      .getElementsByClassName('tab')
      .forEach((element) => element.classList.remove('is-active'));
    event.target.parentNode.classList.add('is-active');
    this.tabContentTargets.forEach((target) => {
      if (target.classList.contains(event.target.id)) {
        target.classList.remove('is-hidden');
      } else {
        target.classList.add('is-hidden');
      }
    });
  }
}

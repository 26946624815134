import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input'];

  connect(){
    // TO DO maybe move away from Select2...
    if ($('span.select2').length == 0){
      $('select').select2({
        width: '100%',
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import {toggleClass, toggleDisabled} from '../util';

export default class extends Controller {
  static targets = ["button", "form"]

  initialize() {
    window.addEventListener('message', (event) => {
      if (event.data?.event === 'calendly.event_scheduled') {
        this.eventScheduled = true;
      }
    });

    this.buttonTarget.addEventListener('click', () => {
      this.toggleButtonSubmittingState(true);
    });
  }

  close() {
    if (this.eventScheduled) {
      this.formTarget.submit();
    } else {
      this.toggleButtonSubmittingState(false);
    }
  }

  toggleButtonSubmittingState(submitting) {
    toggleDisabled(this.buttonTarget, submitting);
    toggleClass(this.buttonTarget, 'is-submitting', submitting);
  }
}

import {Controller} from "@hotwired/stimulus";
import Toastify from 'toastify-js';
import {toast, toastError, toggleClass, toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    "form",
    "success",
  ]

  initialize() {
    if (this.hasFormTarget) {
      toggleHidden(this.successTarget, true);
      toggleHidden(this.formTarget, false);
    }

    this.element.addEventListener('submit', (event) => {
      this.onFormSubmission(event);
    });
  }

  onFormSubmission(event) {
    event.preventDefault();
    const url = this.formTarget.getAttribute('action');
    this.toggleButtonLoader(true);
    $.ajax({
      type: 'POST',
      url,
      contentType: 'application/json',
      success: this.onFormSuccess(),
      error: this.onFormError()
    });
  }

  onFormError() {
    return () => {
      this.toggleButtonLoader(false);
      toastError();
    };
  }

  onFormSuccess() {
    return () => {
      this.toggleButtonLoader(false);
      this.showSuccessState();
    };
  }

  toggleButtonLoader(isLoading) {
    this.formTarget.querySelectorAll('[type="submit"]').forEach(submit => {
      toggleClass(submit, 'is-submitting', isLoading);
      toggleDisabled(submit, isLoading);
    });
  }

  showSuccessState() {
    toggleHidden(this.successTarget, false);
    toggleHidden(this.formTarget, true);
  }

  showFormState() {
    toggleHidden(this.successTarget, true);
    toggleHidden(this.formTarget, false);
  }
}

import { Controller } from '@hotwired/stimulus';
import {toastError, toggleClass, toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {
  static targets = ['promptContent', 'requestedContent'];

  static values = {
    initialState: String,
    url: String,
  }

  initialize() {
    this.promptButtonTarget = this.promptContentTarget.getElementsByClassName('button')[0];

    if (this.initialStateValue === 'prompt') {
      this.showPrompt();

      this.promptButtonTarget?.addEventListener('click', () => {
        this.activate();
      });
    } else {
      this.showRequested();
    }
  }

  showPrompt() {
    toggleHidden(this.promptContentTarget, false);
    toggleHidden(this.requestedContentTarget, true);
  }

  showRequested() {
    toggleHidden(this.promptContentTarget, true);
    toggleHidden(this.requestedContentTarget, false);
  }

  activate() {
    this.toggleButtonSubmit(true);
    $.ajax({
      type: 'POST',
      url: this.urlValue,
      contentType: 'application/json',
      success: this.onSuccess(),
      error: this.onError()
    });
  }
  
  onSuccess() {
    return () => {
      this.toggleButtonSubmit(false);
      this.showRequested();
    };
  }

  onError() {
    return () => {
      this.toggleButtonSubmit(false);
      toastError();
    };
  }

  toggleButtonSubmit(submitting) {
    toggleDisabled(this.promptButtonTarget, submitting);
    toggleClass(this.promptButtonTarget, 'is-submitting', submitting);
  }
}

import Toastify from 'toastify-js';

export function toggleDisabled(element, isDisabled) {
  if (isDisabled) {
    element.setAttribute('disabled', true);
  } else {
    element.removeAttribute('disabled');
  }
}

export function toggleClass(element, className, isIncluded) {
  if (!element) {
    return;
  }

  if (isIncluded) {
    element.classList?.add(className);
  } else {
    element.classList?.remove(className);
  }
}

export function toggleHidden(element, isHidden) {
  toggleClass(element, 'is-hidden', isHidden);
}

export function toggleAriaExpanded(element, isExpanded) {
  if (element) {
    element.setAttribute('aria-expanded', isExpanded);
  }
}

export function toggleRequired(element, isRequired) {
  if (isRequired) {
    element.setAttribute('required', true);
  } else {
    element.removeAttribute('required');
  }
}

export function toggleSubmitting(element, state) {
  element.classList.toggle('is-submitting', state);
}

export function dispatchChangeEvent(element) {
  const event = new Event('change');
  element.dispatchEvent(event);
}

export function toggleActive(element, isActive) {
  if (element) {
    element.classList.toggle('is-active', isActive);
  }
}

export function debounceTime(callback, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { callback.apply(this, args);}, timeout);
  };
}

export function toastError(message = "An error has occurred with our servers. We're sorry for the inconvenience!") {
  Toastify({
    text: message,
    duration: 4000,
    close: true,
    gravity: "top",
    position: 'right',
    offset: {
      x: '2rem',
      y: '6rem'
    },
    stopOnFocus: true
  }).showToast();
}

export function reduceToSentence(array) {
  return array.reduce((sum, current, index) =>
    sum + ((index === array.length - 1) ? ' and ' : ', ') + current
  );
}

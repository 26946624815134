import { Controller } from '@hotwired/stimulus';
import {toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    "page",
    "nextButton",
    "previousButton"
  ];

  get pageIdx() {
    return this._page;
  }

  set pageIdx(value) {
    this._page = value;
    this.updatePages();
    this.updateButtons();
  }

  initialize() {
    this.nextButtonTarget.addEventListener('click', () => {
      this.nextPage();
    });

    this.previousButtonTarget.addEventListener('click', () => {
      this.previousPage();
    });
  }

  connect() {
    this.reset();
  }

  reset() {
    this.pageIdx = 0;
  }

  nextPage() {
    this.pageIdx = Math.min(this.pageIdx + 1, this.pageTargets.length - 1);
  }

  previousPage() {
    this.pageIdx = Math.max(this.pageIdx - 1, 0);
  }

  updatePages() {
    this.pageTargets.forEach((pageTarget, idx) => {
      toggleHidden(pageTarget, this.pageIdx !== idx);
    });
  }

  updateButtons() {
    toggleHidden(this.nextButtonTarget, this.pageIdx >= this.pageTargets.length - 1);
    toggleHidden(this.previousButtonTarget, this.pageIdx < 1 || this.pageTargets.length < 1);
  }
}

import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = [
    'password',
    'confirmPassword',
    'confirmPasswordSubscript'
  ]

  initialize() {
    const events = ['change', 'keyup'];
    this.updateOnTargetEvents(this.passwordTarget, events);
    this.updateOnTargetEvents(this.confirmPasswordTarget, events);
  }

  updateOnTargetEvents(target, events) {
    events.forEach(event => {
      target.addEventListener(event, () => { this.update(); });
    });
  }

  update() {
    this.confirmPasswordTarget.setCustomValidity(this.getMismatchError() ?? '');
    $(this.confirmPasswordTarget).trigger('update');
  }

  getMismatchError() {
    if (this.confirmPasswordTarget.value !== '' && this.passwordTarget.value !== this.confirmPasswordTarget.value) {
      return 'Passwords do not match';
    }
    return null;
  }
}
import { Controller } from '@hotwired/stimulus';
import {toggleClass, toggleDisabled} from '../util';

export default class extends Controller {
  static targets = [
    "sessionSelect",
    "destinationSelect",
    "durationSelect",
    "monthlyCost",
    "totalCost",
    "checkIn",
    "checkOut",
    "button",
    "sessionPeriodIdHiddenInput",
    "sessionYearHiddenInput"
  ];

  static values = {
    campuses: Object,
    checkInOutVisibleUntil: String,
    defaultSelections: Object,
    locked: Boolean,
  }

  initialize() {
    this.campuses = _.cloneDeep(this.campusesValue);

    this.initDestinationSelectChange();
    this.initSessionSelectChange();
    this.initDurationSelectChange();
    this.initDefaultSelectedValues();
    this.initSubmitButton();
    this.maybeInitLockedInState();
  }

  initDefaultSelectedValues() {
    const preSelectedCampusId = this.findCampus(this.defaultSelectionsValue.campusId)?.id;
    $(this.destinationSelectTarget)
      .val(`${preSelectedCampusId != null ? preSelectedCampusId : Object.values(this.campuses)[0].id}`)
      .trigger('change');

    const preSelectedSession = this.findAvailableSession(this.defaultSelectionsValue.session)
      ?? this.selectedCampus.availableSessions[0];
    if (this.campusUnavailable || preSelectedSession == null) {
      return;
    }
    $(this.sessionSelectTarget).val(preSelectedSession.label).trigger('change');

    const preSelectedDuration = this.findAvailableDuration(this.defaultSelectionsValue.duration)
      ?? this.selectedSession.availableDurations[this.selectedSession.minimumDuration];
    if (preSelectedDuration == null) {
      return;
    }
    $(this.durationSelectTarget).val(`${preSelectedDuration.inMonths}`).trigger('change');
  }

  initDestinationSelectChange() {
    $(this.destinationSelectTarget).on('change', () => {
      this.selectedCampus = this.campuses[Number(this.destinationSelectTarget.value)];

      this.updateMonthlyCost();
      this.updateCampusAvailability();
      this.updateSessionSelect();
    });
  }

  initSessionSelectChange() {
    const $sessionSelect = $(this.sessionSelectTarget);
    $sessionSelect.on('change', () => {
      this.selectedSession = this.selectedCampus.availableSessions
        .find(session => `${session.label}` === $sessionSelect.val());

      this.updateSessionsAvailability();
      this.updateDurationSelectOptions();
      this.updateSessionHiddenInputValues();
    });
  }

  initDurationSelectChange() {
    const $durationSelect = $(this.durationSelectTarget);
    $durationSelect.on('change', () => {
      this.selectedDuration = Number($durationSelect.val());
      this.selectedDurationEndPeriod = this.selectedSession?.availableDurations[this.selectedDuration];

      this.validDuration = this.selectedDurationEndPeriod != null;

      this.updateCheckInOutLabels();
      this.updateTotalCostLabel();
      this.updateButton();
    });
  }

  updateCheckInOutLabels() {
    this.checkInTarget.innerText = this.selectedSession?.checkIn ?? "";
    this.checkOutTarget.innerText = this.selectedDurationEndPeriod?.checkOut ?? "";
  }

  updateCampusAvailability() {
    this.campusUnavailable = !(this.selectedCampus?.availableSessions?.length > 0)
      || !(this.selectedCampus?.monthlyCost > 0);
  }

  updateSessionsAvailability() {
    this.sessionsUnavailable = this.campusUnavailable || this.selectedSession?.availableDurations == null
      || this.selectedSession.availableDurations.length < 1;
  }

  updateDurationSelectOptions() {
    $(this.durationSelectTarget).empty();

    if (this.campusUnavailable || this.sessionsUnavailable) {
      this.updateDurationSelectAsUnavailable();
    } else {
      this.updateDurationSelectWithNewCampusDurations();
    }
  }

  updateSessionHiddenInputValues() {
    this.sessionPeriodIdHiddenInputTarget.value = this.selectedSession?.periodId;
    this.sessionYearHiddenInputTarget.value = this.selectedSession?.year;
  }

  updateDurationSelectAsUnavailable() {
    const $durationSelect = $(this.durationSelectTarget);
    this.selectedDuration = null;
    $durationSelect.append(new Option('-', "", true, true)).trigger('change');
    $durationSelect.prop('disabled', true);
  }

  updateDurationSelectWithNewCampusDurations() {
    const $durationSelect = $(this.durationSelectTarget);
    $durationSelect.prop('disabled', false);

    const availableDurationRange = _.range(this.selectedSession.minimumDuration, this.selectedSession.maximumDuration + 1);

    $durationSelect.append(new Option('Duration', "", true, true));
    availableDurationRange
      .forEach(month => {
        const option = new Option(`${month} month${month > 1 ? 's' : ''}`, `${month}`, false, false);
        $durationSelect.append(option);
      });

    $durationSelect.val(this.findAvailableDuration(this.selectedDuration) != null
      ? this.selectedDuration
      : ""
    ).trigger('change');
  }

  updateSessionSelect() {
    $(this.sessionSelectTarget).empty();

    if (this.campusUnavailable) {
      this.updateSessionSelectAsUnavailable();
    } else {
      this.updateSessionSelectWithNewCampusAvailableSessions();
    }
  }

  updateSessionSelectAsUnavailable() {
    const $sessionSelect = $(this.sessionSelectTarget);
    this.selectedSession = null;
    const newOption = new Option('Temporarily Unavailable', "", true, true);
    $sessionSelect.append(newOption).trigger('change');
    $sessionSelect.prop('disabled', true);
  }

  updateSessionSelectWithNewCampusAvailableSessions() {
    const {availableSessions} = this.selectedCampus;
    const $sessionSelect = $(this.sessionSelectTarget);

    $sessionSelect.prop('disabled', false);
    $sessionSelect.append(new Option('Select an option', "", true, true));
    availableSessions.forEach((session, idx) => {
      const option = new Option(session.label, session.label, idx === 0, false);
      $sessionSelect.append(option);
    });

    const equivalentToPreviousSelection = this.findAvailableSession(this.selectedSession?.label);
    if (equivalentToPreviousSelection != null) {
      $sessionSelect.val(equivalentToPreviousSelection.label);
    }
    $sessionSelect.trigger('change');
  }

  findCampus(campusId) {
    return this.campuses[campusId];
  }

  findAvailableSession(sessionLabel) {
    if (this.campusUnavailable) {
      return null;
    }

    return this.selectedCampus.availableSessions.find(session => session.label === sessionLabel);
  }

  findAvailableDuration(durationNumber) {
    if (this.campusUnavailable || this.sessionsUnavailable) {
      return null;
    }
    return this.selectedSession.availableDurations[durationNumber];
  }

  updateMonthlyCost() {
    if (!this.selectedCampus) {
      this.monthlyCost = null;
      return;
    }

    this.monthlyCost = (this.selectedCampus.monthlyCost / 100).toFixed(2);
    this.monthlyCostTarget.innerText = this.monthlyCost.toLocaleString();
  }

  updateTotalCostLabel() {
    if (this.selectedDurationEndPeriod && this.monthlyCost) {
      this.totalCostTarget.innerText = (this.selectedDuration * this.monthlyCost).toLocaleString();
    } else {
      this.totalCostTarget.innerText = "";
    }
  }

  get availableForReservation() {
    return !this.lockedValue && !this.campusUnavailable && !this.sessionsUnavailable && this.validDuration;
  }

  initSubmitButton() {
    if (this.hasButtonTarget) {
      this.element.addEventListener('submit', () => {
        toggleClass(this.buttonTarget, 'is-submitting', true);
        toggleDisabled(this.buttonTarget, true);
      });
    }
  }

  maybeInitLockedInState() {
    if (!this.lockedValue) {
      return;
    }

    $(this.destinationSelectTarget).attr('disabled', true);
    $(this.sessionSelectTarget).attr('disabled', true);
    $(this.durationSelectTarget).attr('disabled', true);

    this.updateButton();
  }

  updateButton() {
    if (this.hasButtonTarget) {
      toggleDisabled(this.buttonTarget, !this.availableForReservation);
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import * as chrono from 'chrono-node';
import {dispatchChangeEvent, toggleDisabled, toggleRequired} from '../util';

// MM/DD/YYYY
const zohoFormat = (d) => {
  const pad = (value) => value.toString().padStart(2, '0');

  return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()}`;
};

const longFormat = (d) => d.toLocaleDateString(undefined, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'});

const toggleDataValid = (element, isValid) => {
  element.setCustomValidity(isValid ? "Invalid input" : "");
  element.reportValidity();
  dispatchChangeEvent(element);
};

export default class extends Controller {
  static targets = [
    'input',
    'feedback',
    'educatedGuess',
    'sanitized',
  ];

  initialize() {
    if (this.hasInputTarget) {
      $(this.inputTarget).on('keyup', () => this.update('keyup'));
      $(this.inputTarget).on('change', () => this.update('change'));
      $(this.inputTarget).on('focus', () => this.toggleFeedbackActive(true));
      $(this.inputTarget).on('blur', () => this.toggleFeedbackActive(false));
    }
    this.update();
  }

  update(eventName = 'change') {
    const chronoResult = chrono.parse(this.inputTarget.value);
    if (chronoResult && chronoResult.length > 0) {
      const date = chronoResult[0].start;
      const isCertain = date.isCertain('day') && date.isCertain('month') && date.isCertain('year');
      const longFormattedDate = longFormat(date.date());
      const zohoFormattedDate = zohoFormat(date.date());
      const prefix = isCertain ? '' : 'Seems like ';
      const suffix = isCertain ? '' : '. That close enough?';
      this.educatedGuessTarget.innerHTML = `\u2713 ${prefix}${longFormattedDate}${suffix}`;
      this.setSanitizedInput(zohoFormattedDate);
    } else {
      this.educatedGuessTarget.innerHTML = '';
      this.setSanitizedInput();
    }

    this.synchroniseInputAttributeWithSanitized('required', toggleRequired);
    this.synchroniseInputAttributeWithSanitized('disabled', toggleDisabled);

    if (this.hasSanitizedTarget) {
      $(this.sanitizedTarget).trigger(eventName);
    }
  }

  synchroniseInputAttributeWithSanitized(attribute, toggleMethod) {
    const inputAttr = !!$(this.inputTarget).attr(attribute);
    if (inputAttr !== !!$(this.sanitizedTarget).attr(attribute)) {
      toggleMethod(this.sanitizedTarget, inputAttr);
    }
  }

  setSanitizedInput(value = ''){
    if (this.hasSanitizedTarget) {
      this.sanitizedTarget.value = value;
      toggleDataValid(this.sanitizedTarget, value !== '');
    }
  }

  toggleFeedbackActive(isActive) {
    if (this.hasFeedbackTarget) {
      this.feedbackTarget.classList.toggle('is-active', isActive);
    }
  }
}

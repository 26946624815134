import { Controller } from '@hotwired/stimulus';
import {toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {
  static targets = ["textarea", "textareaWrapper"];

  static values = {
    isConvicted: String
  }

  onRadioSelect(event) {
    const convicted = event.detail.element.value === "Yes";
    this.updateTextarea(convicted);
  }

  connect() {
    this.updateTextarea(this.isConvictedValue !== "No");

    if (this.isConvictedValue !== "Yes") {
      toggleHidden(this.textareaWrapperTarget, true);
    }
  }

  updateTextarea(convicted) {
    if (!convicted) {
      this.textareaTarget.value = "";
    }
    toggleDisabled(this.textareaTarget, !convicted);
    toggleHidden(this.textareaWrapperTarget, !convicted);

    const $textarea = $(this.textareaTarget);
    $textarea.attr('minlength', convicted ? 1 : 0);
    $textarea.attr('required', convicted);
    $textarea.trigger('change');
  }
}
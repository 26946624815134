import Dropzone from 'dropzone';
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['error', 'input', 'selectFile'];

  initialize() {
    this.dropZone = createDropZone(this);
  }

  connect() {
    this.hideFileInput();
    this.bindEvents();
    this.setError();
    Dropzone.autoDiscover = false;
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  bindEvents() {
    this.dropZone.on('error', (_event, errorMessage) => {
      this.setError(errorMessage);
    })
  }

  setError(message) {
    this.errorTarget.style.display = ( message ? 'block' : 'none');
    this.errorTarget.innerHTML = message || '';
  }

  setLoading(isLoading) {
    if (isLoading) {
      this.selectFileTarget.innerHTML = "<i class=\"fa fa-spinner fa-spin\" style=\"margin-right: 10px;\"></i>Loading..."
    } else {
      this.selectFileTarget.innerHTML = "Select File"
    }
  }

  handleAddedFile() {
    const handleLoad = (event) => {
      const parent = document.querySelector("#cropper-image");
      const img = document.createElement('img');
      img.src = event.target.result;
      img.style.maxWidth = '600px';
      img.style.maxHeight = '600px';
      parent.appendChild(img);
      this.setupCropper(img);
    }

    return (file) => {
      if (file.size <= this.maxFileSize * 1048576) { // bytes
        const fileReader = new FileReader();
        fileReader.onload = handleLoad;
        this.setError();
        this.setLoading(true);
        fileReader.readAsDataURL(file);
      }
    }
  }

  setupCropper(imageElement) {
    this.cropperController.setup(imageElement);
  }

  reset() {
    const parent = document.querySelector("#cropper-image");
    parent.innerHTML = '';
    if (this.dropZone) {
      this.dropZone.removeAllFiles();
    }
    this.setError();
    this.setLoading(false);
  }

  save() {
    this.dropZone.processQueue();
  }

  get cropperController() {
    const el = document.getElementById('cropper')
    return this.application.getControllerForElementAndIdentifier(el, 'cropper');
  }

  get maxFileSize() {
    return 15;
  }
}

function createDropZone(controller) {
  const paramName = document.getElementById('cropperModal').dataset.paramName;

  return new Dropzone(controller.element, {
    acceptedFiles: 'image/*',
    addedfile: controller.handleAddedFile(),
    addRemoveLinks: true,
    autoProcessQueue: false,
    success: handleDropzoneSuccess,
    createImageThumbnails: false,
    maxFiles: 1,
    maxFilesize: controller.maxFileSize, // mb
    paramName,
  });
}

function handleDropzoneSuccess() {
  window.location.reload();
}

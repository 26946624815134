import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'input',
    'toggle',
  ];

  toggleCleartext() {
    if (this.inputTarget) {
      if (this.inputTarget.getAttribute('type') === 'password') {
        this.inputTarget.setAttribute('type', 'text');
        this.toggleTarget?.setAttribute('aria-pressed', true);
      } else {
        this.inputTarget.setAttribute('type', 'password');
        this.toggleTarget?.setAttribute('aria-pressed', false);
      }
    }
  }
}

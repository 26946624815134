import { Controller } from '@hotwired/stimulus';
import {reduceToSentence, toggleHidden} from '../util';

export default class extends Controller {
  static targets = ['card',
    'newSkillsModalBody',
    'noNewSkillsModalBody',
    'learningTopicIdInput',
    'newSkillsTags',
    'learningTopicSkills'
  ];

  initialize() {
    this.cardTargets.forEach(card => {
      this.initializeCardTarget(card);
    });
    this.candidateSkills = JSON.parse(this.element.dataset.candidateSkills);
  }

  initializeOtherCourses() {
    $('.my-other-courses .learning-topic.card').toArray().forEach(card => this.initializeCardTarget(card));
  }

  initializeCardTarget(card) {
    const checkbox = $(card).find('.mark-as-complete-checkbox').first();
    checkbox.prop('checked', false);

    checkbox?.on('change', () => {
      if (checkbox.prop('checked')) {
        this.confirm(card, checkbox.get(0));
      }
    });
  }

  confirm(card, checkbox) {
    this.selectedCheckbox = checkbox;
    this.selectedTopic = card;
    this.topicSkills = JSON.parse(card.dataset.skills);
    this.learningTopicIdInputTarget.value = card.dataset.id;
    this.updateModal();
  }

  updateModal() {
    const newSkills = this.topicSkills.filter(skill => !this.candidateSkills.includes(skill));

    if (newSkills.length < 1) {
      this.renderModalInNoNewSkillsState();
    } else {
      this.renderModalInNewSkillsState(newSkills);
    }

    this.dispatch('confirm');
  }

  renderModalInNoNewSkillsState() {
    toggleHidden(this.noNewSkillsModalBodyTarget, false);
    toggleHidden(this.newSkillsModalBodyTarget, true);
    this.learningTopicSkillsTarget.innerText = reduceToSentence(this.topicSkills);
  }

  renderModalInNewSkillsState(newSkills) {
    toggleHidden(this.newSkillsModalBodyTarget, false);
    toggleHidden(this.noNewSkillsModalBodyTarget, true);


    this.newSkillsTagsTarget.innerHTML = '';
    newSkills.forEach(skill => {
      const listElement = document.createElement("li");
      listElement.appendChild(document.createTextNode(skill));
      this.newSkillsTagsTarget.appendChild(listElement);
    });
  }

  cancel() {
    if (this.selectedCheckbox) {
      $(this.selectedCheckbox).prop('checked', false);
    }
    this.selectedCheckbox = null;
    this.topicSkills = null;
  }
}

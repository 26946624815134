import { Controller } from "@hotwired/stimulus";

const percentageProgress = fractionalProgress => Math.round(fractionalProgress * 100);
export default class extends Controller {
  static targets = ['button', 'callout', 'cursor', 'input', 'rail', 'wrapper'];

  static values = {
    minimumSelected: Number,
  }

  connect() {
    this.update();
  }

  update() {
    const nSelected = this.numberSelected;
    const fractionalProgress = nSelected / this.totalSelectable;

    this.updateButtons(nSelected);
    this.updateCalloutMessage(nSelected);
    this.updateRail(fractionalProgress);
    this.updateCursorPosition(fractionalProgress);
    this.updateWrapper(nSelected);
  }

  updateButtons(nSelected) {
    this.buttonTargets.forEach(element => element.toggleAttribute('disabled', nSelected < this.minimumSelectedValue));
  }

  updateRail(fractionalProgress) {
    this.railTarget.style.width = `${percentageProgress(fractionalProgress)}%`;
  }

  updateCursorPosition(fractionalProgress) {
    const declaration = `
      --percentage-progress: ${percentageProgress(fractionalProgress)}%;
      --fractional-progress: ${fractionalProgress};
    `;

    this.calloutTarget.setAttribute('style', declaration);
    this.cursorTarget.setAttribute('style', declaration);
  }

  updateCalloutMessage(nSelected) {
    const { totalSelectable } = this;
    const nAboveMinimum = totalSelectable - this.minimumSelectedValue;
    const waypointA = this.minimumSelectedValue + Math.floor(nAboveMinimum / 3.0);
    const waypointB = this.minimumSelectedValue + Math.floor(2.0 * nAboveMinimum / 3.0);
    const nSelectedMessage = `${nSelected} of ${totalSelectable}.`;
    let calloutMessage = '';
    if (nSelected === totalSelectable) {
      calloutMessage = ` 💯🔥`;
    } else if (nSelected === 0) {
      calloutMessage = ` Let's go!`;
    } else if (_.includes([1, 2], nSelected)) {
      calloutMessage = ` Keep going.`;
    } else if (_.includes([3, 4], nSelected)) {
      calloutMessage = ` Almost there!`;
    } else if (_.includes([totalSelectable - 1, totalSelectable - 2], nSelected)) {
      calloutMessage = ` Wow, almost all of them 😮`;
    } else if (_.inRange(nSelected, this.minimumSelectedValue, waypointA)) {
      calloutMessage = ` Add extras!`;
    } else if (_.inRange(nSelected, waypointA, waypointB)) {
      calloutMessage = ` The more the merrier!`;
    } else if (nSelected >= waypointB) {
      calloutMessage = ` Amazing!`;
    }

    this.calloutTarget.innerHTML = nSelectedMessage + calloutMessage;
  }

  updateWrapper(nSelected) {
    this.wrapperTarget.setAttribute('aria-valuenow', nSelected);
  }

  get totalSelectable() {
    return this.inputTargets.length;
  }

  get numberSelected() {
    return this.inputTargets.filter(element => element.value === "true" ).length;
  }
}

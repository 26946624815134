import { Controller } from "@hotwired/stimulus";
import WindowScrollingService from '../services/window_scrolling_service';

export default class extends Controller {
  static targets = [
    "infoSection",
    "selectedModulesWrapper"
  ]

  initialize() {
    WindowScrollingService.toggleClassWhenScrollPast(
      this.selectedModulesWrapperTarget,
      this.infoSectionTarget,
      'scroll-past-info-section',
      -200
    );
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['player'];

  stop() {
    const resetSrc = this.playerTarget.src;
    this.playerTarget.src = resetSrc;
  }


}
import { Controller } from "@hotwired/stimulus";

const handleSubmit = (event) => {
  event.preventDefault();
  cu.InterviewScheduling.availability.copyToHiddenInput();
  const openConfirmationModalEvent = new Event('confirm-submit-availability-modal');
  document.dispatchEvent(openConfirmationModalEvent);
};

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('submit', handleSubmit);
    }
  }

  submit() {
    if (this.formTarget) {
      const busyEvent = new Event('busy:begin');
      this.formTarget.dispatchEvent(busyEvent);

      if (cu && cu.ProtectUnsavedChanges) {
        cu.ProtectUnsavedChanges.unsavedChanges = false;
      }

      // This delay is needed for Safari to complete making the styling and content changed to
      // the button that are triggered by the busy:begin event.
      setTimeout(() => {
        this.formTarget.submit();
      }, 200);
    }
  }
}

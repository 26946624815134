import {Controller} from "@hotwired/stimulus";
import {toggleDisabled, toggleHidden, toggleRequired} from '../util';

export default class extends Controller {
  static targets = [
    'graduationDateInput',
    'graduationDateSanitizedInput',
    'graduationDateWrapper',
    'expectedGraduationInput',
    'expectedGraduationWrapper',
    'educationStatusInput'
  ]

  connect() {
    this.EDUCATION_STATUS_ENROLLED = $(this.educationStatusInputTarget).data('education-status-enrolled');
    this.EDUCATION_STATUS_NOT_ENROLLED = $(this.educationStatusInputTarget).data('education-status-not-enrolled');
    this.GRADUATED = $(this.expectedGraduationInputTarget).data('expected-graduation-graduated');

    $(this.expectedGraduationInputTarget).on('change', () => {
      this.handleExpectedGraduationChange();
    });

    if (this.educationStatusInputTarget.value?.length > 0) {
      this.handleExpectedGraduationChange();
    }
  }

  handleExpectedGraduationChange() {
    if (!this.hasCandidateGraduated) {
      $(this.educationStatusInputTarget).val(this.EDUCATION_STATUS_ENROLLED);
      this.disableInput('graduationDate');
    } else {
      $(this.educationStatusInputTarget).val(this.EDUCATION_STATUS_NOT_ENROLLED);
      this.enableInput('graduationDate');
    }
  }

  disableInput(name) {
    const inputTarget = this[`${name}InputTarget`];
    $(inputTarget).val('');
    $(inputTarget).trigger('change');
    toggleDisabled(inputTarget, true);
    toggleHidden(this[`${name}WrapperTarget`], true);
    toggleRequired(inputTarget, false);
    $(inputTarget).trigger('change');
  }

  enableInput(name) {
    const inputTarget = this[`${name}InputTarget`];
    toggleHidden(this[`${name}WrapperTarget`], false);
    toggleDisabled(inputTarget, false);
    toggleRequired(inputTarget, true);
    $(inputTarget).trigger('change');
  }

  get hasCandidateGraduated() {
    return this.expectedGraduationInputTarget.value === this.GRADUATED;
  }
}
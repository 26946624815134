import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['card', 'input'];

  connect() {
    if (this.hasInputTarget) {
      const selected = this.cardTarget.classList.contains('selected');
      $(this.inputTarget).val(selected);
    }
  }
}

import {Controller} from "@hotwired/stimulus";
import { toggleActive } from '../util';

export default class extends Controller {
    static targets = ['trigger', 'menu', 'background'];

    initialize() {
        this.isActive = false;
    }

    toggleActive() {
        this.isActive = !this.isActive;
        toggleActive(this.triggerTarget, this.isActive);
        toggleActive(this.menuTarget, this.isActive);
        toggleActive(this.backgroundTarget, this.isActive);
    }
}

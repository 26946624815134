import {Controller} from "@hotwired/stimulus";
import {toggleHidden} from '../util';

export default class extends Controller {
  ONE_HUNDRED_MEGABYTES = 100000000;

  ACCEPTED_FILETYPES = [
    'application/pdf',
    'application/msword;application',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]

  static targets = [
    "fileUrlHiddenInput",
    "fileNameHiddenInput",
    "filePreviewCardName",
    "filePreviewCardExtensionName",
    "filePreviewCardExtensionImg",
    "filePreviewCardDeleteButton",
    "filePreviewCardDownloadButton",
    "filePreviewCardWrapper",
    "dropzone",
    "dropzoneButton",
    "dropzoneError",
    "input",
    "fileExtensionAssets",
    "filePresenceInput"
  ];

  initialize() {
    this.initializeFileExtensionAssets();
    this.initializeFilePreviewCard();
    this.initializeFileInput();
  }

  initializeFileExtensionAssets() {
    this.assetExtensions = $(this.fileExtensionAssetsTarget).data('extensions');
  }

  initializeFilePreviewCard() {
    this.fileInfo = this.getFileInfoFromResumeUrlInputValue();
    if (this.hasFilePreviewCardDeleteButtonTarget) {
      $(this.filePreviewCardDeleteButtonTarget).on('click', () => {
        this.clearUploadedResume(null, true);
      });
    }
  }

  getExtensionSrcPathFromName(name) {
    if (name == null) {
      return null;
    }
    return this.assetExtensions?.find((extension => extension.name?.toLowerCase() === name.toLowerCase()))?.path;
  }

  getFileInfoFromResumeUrlInputValue() {
    const resumeUrl = this.fileUrlHiddenInputTarget.value;
    const resumeFileName = this.fileNameHiddenInputTarget.value;

    if (resumeUrl?.length > 0) {
      const fileBasename = resumeUrl.substring(resumeUrl.lastIndexOf('/') + 1);

      if (!(fileBasename?.length > 0)) {
        return {
          name: null,
          extension: null,
          url: resumeUrl
        };
      }

      return {
        url: resumeUrl,
        ...this.splitBasenameIntoFileInfo(fileBasename, resumeFileName)
      };
    }
    return null;
  }

  updateImgSrcForFilePreviewCard() {
    const path = this.getExtensionSrcPathFromName(this.fileInfo?.extension) ?? this.getExtensionSrcPathFromName('other');
    toggleHidden(this.filePreviewCardExtensionImgTarget, path == null);
    $(this.filePreviewCardExtensionImgTarget).attr('src', path);
  }

  updateFileNameForFilePreviewCard() {
    $(this.filePreviewCardNameTarget).text(this.fileInfo?.name ?? "Resume");
  }

  updateFileExtensionForFilePreviewCard() {
    const extension = this.fileInfo?.extension;
    if (!extension) {
      $(this.filePreviewCardExtensionNameTarget).empty();
    } else {
      const queuedDecorator = this.fileInfo?.queued ? ' (pending upload)' : '';
      $(this.filePreviewCardExtensionNameTarget).text(`${extension.toString().toUpperCase()} file ${queuedDecorator}`);
    }
  }

  updateUploadedResumeDownloadButton() {
    if (this.hasfilePreviewCardDownloadButtonTarget) {
      const url = this.fileInfo?.url;
      toggleHidden(this.filePreviewCardDownloadButtonTarget, url == null);
      $(this.filePreviewCardDownloadButtonTarget).attr('href', url);
    }
  }

  updateFilePreviewCardVisibility() {
    toggleHidden(this.filePreviewCardWrapperTarget, !this.isFileUploaded);
  }

  clearUploadedResume(errorMessage = null, dispatch = false) {
    $(this.fileUrlHiddenInputTarget).val(null);
    this.fileInfo = null;
    $(this.inputTarget).val(null);
    this.setError(errorMessage);
    if (dispatch) {
      this.inputTarget.dispatchEvent(new Event('change'));
    }
  }

  initializeFileInput() {
    this.acceptedFileTypes = $(this.inputTarget).attr('accept')?.split(',') ?? [];

    $(this.inputTarget).on('change', () => {
      this.setUploadedResume();
    });
  }

  setUploadedResume() {
    const files = this.inputTarget.files;

    if (!(files?.length > 0)) {
      return;
    }

    const error = this.validateInputFile(files);
    if (error) {
      this.clearUploadedResume(error);
    } else {
      this.setError(null);
    }

    this.fileInfo = {
      ...this.splitBasenameIntoFileInfo(files.item(0).name),
      url: null,
      queued: true
    };

    $(this.fileNameHiddenInputTarget).val(this.fileInfo.name);
  }

  validateInputFile(files) {
    if (files == null || files.length < 1) {
      return "Please select a file to upload.";
    }

    if (files.length > 1) {
      return "Please select a single file for upload.";
    }

    const file = files.item(0);

    if (!this.ACCEPTED_FILETYPES.includes(file.type)) {
      return "You can't upload files of this type.";
    }

    if (file.size > this.ONE_HUNDRED_MEGABYTES) {
      return "File size must not be bigger than 100 MB.";
    }
    return null;
  }

  hideFileInput() {
    toggleHidden(this.inputTarget, true);
  }

  setError(message) {
    toggleHidden(this.dropzoneErrorTarget, !message);
    this.dropzoneErrorTarget.innerHTML = message || '';
  }

  updateDropzoneVisibility() {
    toggleHidden(this.dropzoneTarget, this.isFileUploaded);
  }

  updateFilePresenceFlag() {
    if (this.hasFilePresenceInputTarget) {
      this.filePresenceInputTarget.value = this.fileInfo != null ? true : "";
    }
  }

  get fileInfo() {
    return this._fileInfo;
  }

  set fileInfo(value) {
    this._fileInfo = value;
    this.updateFileExtensionForFilePreviewCard();
    this.updateFileNameForFilePreviewCard();
    this.updateImgSrcForFilePreviewCard();
    this.updateUploadedResumeDownloadButton();
    this.updateFilePreviewCardVisibility();
    this.updateDropzoneVisibility();
    this.updateFilePresenceFlag();
  }

  get isFileUploaded() {
    return this.fileInfo != null;
  }

  splitBasenameIntoFileInfo(basename, originalFileName = null) {
    const basenameArray = basename?.split('.');

    if (basenameArray == null || basenameArray.length < 1) {
      return {
        name: "Resume",
        extension: null
      }
    }

    const name = basenameArray[0];
    let extension = basenameArray[basenameArray.length - 1];
    if (extension == null || extension === name) {
      extension = null;
    }

    return {
      name: originalFileName?.length > 0 ? originalFileName : name,
      extension: extension
    }
  }
}

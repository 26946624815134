import { Controller } from "@hotwired/stimulus";
import { Events } from '../events';

const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;

const isValidDate = value => !isNaN(value);
const computeTimeRemaining = (deadlineDate) => {
  const currentDate = new Date();
  const diff = deadlineDate - currentDate;
  if (diff <= 0) {
    return {days: 0, hours: 0, minutes: 0, seconds: 0, isZero: true};
  }

  let remainder = diff;
  const days = Math.floor(diff / MILLIS_PER_DAY);
  remainder -= days * MILLIS_PER_DAY;
  const hours = Math.floor(remainder / MILLIS_PER_HOUR);
  remainder -= hours * MILLIS_PER_HOUR;
  const minutes = Math.floor(remainder / MILLIS_PER_MINUTE);
  remainder -= minutes * MILLIS_PER_MINUTE;
  const seconds = Math.floor(remainder / MILLIS_PER_SECOND);

  return {days, hours, minutes, seconds, isZero: false};
};

export default class extends Controller {
  static targets = ['banner', 'days', 'hours', 'minutes', 'seconds', 'close'];
  static values = {
    deadline: String,
  };

  initialize() {
    const deadline = new Date(this.deadlineValue);
    if (isValidDate(deadline)) {
      this.deadline = deadline;
      const timeRemaining = computeTimeRemaining(this.deadline);
      this.initiallyZero = timeRemaining.isZero;
    }
    this.emitCountdownOpenedEvent();
  }

  connect() {
    this.updateCounter();
  }

  disconnect() {
    this.stopTimer();
  }

  emitCountdownOpenedEvent() {
    setTimeout(() => {
      document.dispatchEvent(new Event('countdownOpened'));
    }, 0);
  }

  stopTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
    }
  }

  updateCounter() {
    if (this.deadline) {
      const timeRemaining = computeTimeRemaining(this.deadline);
      ['days', 'hours', 'minutes', 'seconds'].forEach((key) => {
        this[key] = timeRemaining[key];
        this[`${key}Target`].innerHTML = this[key].toString().padStart(2, '0');
      });
      if (!timeRemaining.isZero) {
        this.timeoutId = setTimeout(() => this.updateCounter(), MILLIS_PER_SECOND);
      } else {
        this.bannerTarget.classList.add("past-deadline");
        if (!this.initiallyZero) {
          const event = new Event(Events.deadlineReached);
          document.dispatchEvent(event);
        }
      }
    }
  }

  hide() {
    document.dispatchEvent(new Event('countdownClosed'));
    this.bannerTarget.classList.add('is-hidden');
  }
}



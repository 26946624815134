import {Controller} from "@hotwired/stimulus";
import {toggleHidden} from '../util';

export default class extends Controller {
    static targets = ['menu', 'button', 'input', 'option', 'buttonLabel'];

    get dropdownOpen() {
        return this._dropdownOpen;
    }

    initialize() {
        this.options = {};
        this.optionTargets.forEach((target, idx) => {
            const value = target.dataset.optionValue ?? idx;
            this.options[value] = target;
        });

        this.labels = {};
        this.buttonLabelTargets.forEach((target, idx) => {
           const value = target.dataset.optionValue ?? idx;
           this.labels[value] = target;
        });
    }

    connect() {
        this.dropdownOpen = false;
    }

    disconnect() {
        this.dropdownOpen = false;
    }

    set dropdownOpen(value) {
        if (this.dropdownOpen === value) {
            return;
        }

        if (value) {
            this.menuTarget.classList.add('opened');
            window.addEventListener('scroll', this._noScroll());
        } else {
            this.menuTarget.classList.remove('opened');
            window.removeEventListener('scroll', this.scrollListener);
        }

        this._dropdownOpen = value;
    }

    toggle(event) {
        this.dropdownOpen = !this.dropdownOpen;

        if (!this.isEventTriggeredByKeydown(event)) {
            this.buttonTarget.blur();
        }
    }

    close() {
        if (this.dropdownOpen) {
            this.dropdownOpen = false;
        }
    }

    selectTarget(event) {
        const option = event.target.dataset.optionValue;
        if (option == null) {
            return;
        }

        this.selectOption(option);
    }

    updateFromTabs(controllerEvent) {
        this.selectOption(controllerEvent.detail.id);
    }

    selectOption(option) {
        if (!Object.keys(this.options).includes(option) || this.value === option) {
            return;
        }

        if (this.options[option].dataset.isUrl === "true") {
            return;
        }

        this.value = option;
        this.updateSelectableOptions();
        this.updateButtonLabel();
        this.updateInputTarget();
        this.dispatch('select', {detail: {
            value: this.value,
            target: this.options[this.value]
        }});
        this.dropdownOpen = false;
    }

    updateSelectableOptions() {
        const value = this.value;

        Object.values(this.options).forEach(target => {
            toggleHidden(target, false);
        });
        toggleHidden(this.options[value], true);
    }

    updateButtonLabel() {
        const value = this.value;

        Object.values(this.labels).forEach(target => {
            toggleHidden(target, true);
        });
        toggleHidden(this.labels[value], false);
    }

    updateInputTarget() {
        this.inputTarget.value = this.value;
    }

    isEventTriggeredByKeydown(event) {
        return event.clientX === 0 && event.clientY === 0 && event.layerX === 0 && event.layerY === 0;
    }

    _noScroll() {
        const scrollCoords = {x: window.scrollX, y: window.scrollY}

        this.scrollListener = () => {
            window.scrollTo(scrollCoords.x, scrollCoords.y);
        };

        return this.scrollListener;
    }
}
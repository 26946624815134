import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'input'];

  initialize() {
    $(this.inputTarget).on('change', (event) => {
      this.updateViewState(this.inputTarget.value);
    });
  }

  connect() {
    this.updateViewState();
  }

  toggleCheck() {
    const checked = !(this.inputTarget.value === 'true');
    $(this.inputTarget).val(checked).change();
  }

  updateViewState() {
    const selected = this.inputTarget.value === 'true';
    this.buttonTarget.setAttribute('aria-checked', String(selected));
  }
}

import { Controller } from '@hotwired/stimulus';
import {toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    "template",
    "loader"
  ]

  static values = {
    url: String
  }

  initialize() {
    this.url = this.urlValue;
    toggleHidden(this.loaderTarget, false);
    toggleHidden(this.templateTarget, true);
  }

  load() {
    if (this.loaded) {
      return;
    }

    this.reload();
  }

  reload() {
    toggleHidden(this.loaderTarget, false);
    toggleHidden(this.templateTarget, true);
    this.templateTarget.innerHTML = '';

    this.loaded = true;
    $.ajax({
      type: 'GET',
      url: `${this.url}.js`,
      contentType: 'application/json',
      data: this.args,
      success: this.onLoadSuccess()
    });
  }

  onLoadSuccess() {
    return (response) => {
      toggleHidden(this.loaderTarget, true);
      // eslint-disable-next-line no-eval
      eval(response);
      toggleHidden(this.templateTarget, false);
      this.dispatch('load');
    };
  }

  get args() {
    const unparsedArgs = this.templateTarget.dataset?.args;
    if (!unparsedArgs) {
      return null;
    }

    return JSON.parse(unparsedArgs);
  }
}

import { Controller } from "@hotwired/stimulus";

const START_DELAY = 200;
const DURATION = 6500; // milliseconds to fill all progress bars

export default class extends Controller {
  static targets = ['progress', 'spinner', 'checkmark', 'placeholder', 'complete', 'form'];

  initialize() {
    this.initializeSymbols();
    setTimeout(() => { this.initializeProgressBars(); }, START_DELAY);
  }

  initializeSymbols() {
    this.placeholderTargets.forEach(target => target.classList.toggle('is-active', true));
    this.spinnerTargets.forEach(target => target.classList.remove('is-active'));
    this.checkmarkTargets.forEach(target => target.classList.remove('is-active'));
  }

  initializeProgressBars() {
    if (this.hasProgressTarget) {
      this.animationComplete = false;
      this.currentProgressTarget = this.progressTargets[0];
      this.currentTargetIndex = 0;
      this.animationInterval = DURATION / (this.progressTargets.length * 100); // millis between increasing current progress bar by 1%
      this.progressBarTimeout = setTimeout(() => { this.updateProgressBars(); }, this.animationInterval);
      const { name } = this.currentProgressTarget.dataset;
      this.show('spinner', name);
    } else {
      this.finalizeAnimation();
    }
  }

  show(symbol, name) {
    const update = (target, group) => {
      if (target.dataset.name === name) {
        target.classList.toggle('is-active', symbol === group);
      }
    }
    this.spinnerTargets.forEach(target => update(target, 'spinner'));
    this.placeholderTargets.forEach(target => update(target, 'placeholder'));
    this.checkmarkTargets.forEach(target => update(target, 'checkmark'));
  }

  updateProgressBars() {
    if (this.currentProgressTarget) {
      const currentValue = parseInt(this.currentProgressTarget.getAttribute("value"), 10);
      if (currentValue < 98) {
        this.setProgress(currentValue + 1, currentValue);
        this.progressBarTimeout = setTimeout(() => { this.updateProgressBars() }, this.animationInterval);
      } else {
        this.setProgress(100, currentValue);
        this.handleFullProgressBar();
      }
    }
  }

  setProgress(newValue, currentValue) {
    if (this.currentProgressTarget && newValue !== currentValue) {
      this.currentProgressTarget.setAttribute('value', newValue);
    }
  }

  handleFullProgressBar() {
    const { name } = this.currentProgressTarget.dataset;
    this.show('checkmark', name);
    if (this.isLastProgressBar()) {
      this.finalizeAnimation();
    } else {
      this.moveToNextProgressBar();
    }
  }

  finalizeAnimation() {
    this.animationComplete = true;
    if (this.hasCompleteTarget) {
      this.completeTarget.classList.toggle('is-active', true);
    }
    if (this.hasFormTarget) {
      this.formTarget.submit();
    }
  }

  moveToNextProgressBar() {
    this.currentTargetIndex += 1;
    this.currentProgressTarget = this.progressTargets[this.currentTargetIndex];
    this.progressBarTimeout = setTimeout(() => { this.updateProgressBars(); }, this.animationInterval);
    const { name } = this.currentProgressTarget.dataset;
    this.show('spinner', name);
  }

  isLastProgressBar() {
    return this.currentTargetIndex === (this.progressTargets.length - 1);
  }

  maybeLaunch() {
    if (this.targetStageReached && this.animationComplete) {
      if (this.progressBarTimeout) {
        clearTimeout(this.progressBarTimeout);
      }
      if (this.pollingTimeout) {
        clearTimeout(this.pollingTimeout);
      }
      window.location.href = '/home';
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import {toggleClass, toggleDisabled, toggleHidden} from '../util';

export default class extends Controller {
  static targets = [
    "scheduleButton",
    "checkmarkList",
    "emptyListMessage",
    "coachingSession",
    "sessionCheckbox",
    "selectedModulesCount",
    "calendlyExtraDataDropPoint"
  ];

  initialize() {
    this.selectedModuleIds = [];
    this.initializeDefaultState();
  }

  clearSelectedModules() {
    [...this.selectedModuleIds].forEach(id => {
      const checkbox = this.findSessionCheckboxBySessionId(id);
      $(checkbox).prop('checked', !checkbox.checked);
      this.updateSessionSelect(id, false);
    });
  }

  initializeDefaultState() {
    if (this.hasScheduleButtonTarget) {
      toggleDisabled(this.scheduleButtonTarget, true);
    }

    this.checkmarkListItems.forEach((element) => {
      toggleHidden(element, true);
    });

    this.emptyListMessageTargets.forEach(element => {
      toggleHidden(element, false);
    });

    window.addEventListener('message', (event) => {
      if (event.data?.event === 'calendly.event_scheduled') {
        this.clearSelectedModules();
      }
    });
  }

  toggleSessionSelectByClick(event) {
    const session = this.findSessionListItemFromEventTargetNode(event.target);
    if (!session) {
      return;
    }

    const sessionId = this.getSessionIdFromElement(session);
    const checkbox = this.findSessionCheckboxBySessionId(sessionId);
    $(checkbox).prop('checked', !checkbox.checked);
    this.updateSessionSelect(sessionId, checkbox.checked);
  }

  toggleSessionSelectByCheckbox(event) {
    const checkbox = event.target;
    const sessionId = parseInt(checkbox.dataset.sessionId, 10);
    this.updateSessionSelect(sessionId, checkbox.checked);
  }

  updateSessionSelect(sessionId, selected) {
    this.toggleSessionFromSelectedModulesArray(sessionId, selected);
    this.toggleSessionFromView(sessionId, selected);
    this.updateCalendlyWidgetExtraData();
  }

  updateCalendlyWidgetExtraData() {
    if (!this.hasCalendlyExtraDataDropPointTarget) {
      return;
    }

    this.calendlyExtraDataDropPointTarget.dataset.prefill = JSON.stringify({
      customAnswers: {
        a1: _.map(this.selectedModuleIds, (id => parseInt(id, 10) + 1))
      }
    });

    this.calendlyExtraDataDropPointTarget.dataset.utmContent = JSON.stringify({coachingSessions: this.selectedModuleIds});
  }

  findSessionListItemFromEventTargetNode(target) {
    if (target.classList.contains('coaching-session')) {
      return target;
    }
    if (target.classList.contains('video-preview')
      || !target.offsetParent?.classList.contains('coaching-session')) {
      return null;
    }
    return target.offsetParent;
  }

  toggleSessionFromSelectedModulesArray(id, newlySelected) {
    if (newlySelected) {
      this.selectedModuleIds.push(id);
    } else {
      const index = this.selectedModuleIds.indexOf(id);
      if (index >= 0) {
        this.selectedModuleIds.splice(index, 1);
      }
    }
  }

  toggleSessionFromView(id, selected) {
    toggleClass(this.findCoachingSessionListItemBySessionId(id), 'selected', selected);
    this.findCheckmarkListItemsBySessionId(id).forEach(element => {
      toggleHidden(element, !selected);
    });

    const noModulesSelected = this.selectedModuleIds.length < 1;

    if (this.hasScheduleButtonTarget) {
      toggleDisabled(this.scheduleButtonTarget, noModulesSelected);
    }

    this.selectedModulesCountTarget.getElementsByTagName('span')[0].innerText = this.selectedModuleIds.length;
    toggleClass(this.selectedModulesCountTarget, 'no-modules', noModulesSelected);

    this.emptyListMessageTargets.forEach(element => {
      toggleHidden(element, !noModulesSelected);
    });
  }

  findCoachingSessionListItemBySessionId(id) {
    return this.coachingSessionsListItems.find(element => element.dataset.sessionId === id.toString());
  }

  get coachingSessionsListItems() {
    return [...this.coachingSessionTargets];
  }

  findCheckmarkListItemsBySessionId(id) {
    return this.checkmarkListItems.filter(element => element.dataset.sessionId === id.toString());
  }

  get checkmarkListItems() {
    return _.flatMap(this.checkmarkListTargets, target => [...target.getElementsByTagName('li')]);
  }

  findSessionCheckboxBySessionId(id) {
    return this.sessionCheckboxListItems.find(element => element.dataset.sessionId === id.toString());
  }

  get sessionCheckboxListItems() {
    return [...this.sessionCheckboxTargets];
  }

  getSessionIdFromElement(element) {
    return parseInt(element.dataset.sessionId, 10);
  }
}

import {debounceTime, toggleClass} from '../util';

export default class WindowScrollingService {
  static locker = 0;

  static unlock() {
    this.locker = Math.min(this.locker - 1, 0);
    this.scrollTop = document.documentElement.scrollTop;

    if (WindowScrollingService.locker < 1) {
      this.forceEnable();
    }
  }

  static lock() {
    this.locker -= 1;


    window.oldScrollPos = $(window).scrollTop();

    $(window).on('scroll.scrolldisabler',function ( event ) {
      $(window).scrollTop( window.oldScrollPos );
      event.preventDefault();
    });
  }

  static forceEnable() {
    this.locker = 0;

    $(window).off('scroll.scrolldisabler');
  }

  static toggleClassWhenScrollPast(targetElement, pastElement, className, offset = 0) {
    $(window).scroll(function() {
      const windowScroll = $(window).scrollTop();
      const pastElementPosition = $(pastElement).offset().top;
      const pastElementHeight = $(pastElement).height();

      toggleClass(targetElement, className, windowScroll > pastElementPosition + pastElementHeight + offset);
    });
  }
}